import React, { useState, useEffect, Fragment } from "react";
import {
  SearchIcon,
  ExclamationIcon,
  XIcon,
  LightningBoltIcon,
  GiftIcon,
} from "@heroicons/react/outline";
import { Dialog, Transition } from "@headlessui/react";

import Navbar from "./Navbar";
import ListViewRecording from "./ListViewRecording";
import InviteModal from "./InviteModal";
import amplitude from "amplitude-js";

import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import "tippy.js/themes/light.css";

import "../index.css";
var moment = require("moment");

const Home = (props) => {
  const [userEmail, setUserEmail] = useState(null);
  const [user, setUser] = useState(null);
  const [logout, setLogout] = useState(false);

  const [recordings, setRecordings] = useState(null);
  const [recordingsToDisplay, setRecordingsToDisplay] = useState([]);

  const [searchTerm, setSearchTerm] = useState(null);

  /*Delete Recording Modal*/
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [recordingToDelete, setRecordingToDelete] = useState(null);

  const [showIncentiveBanner, setShowIncentiveBanner] = useState(true);
  const [openBannerInviteModal, setOpenBannerInviteModal] = useState(false);

  // Pagination
  const pageLength = 15;
  const [currentPage, setCurrentPage] = useState(0);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getRecordings = async () => {
      if (user) {
        setLoading(true);
        setCurrentPage(0);

        await fetchRecordings();
        setLoading(false);
      }
    };
    getRecordings();
  }, [user, userEmail, props.view]);

  useEffect(() => {
    if (recordings) {
      setRecordingsToDisplay(recordings);
    }
  }, [recordings]);

  useEffect(() => {
    if (
      user &&
      user.subscription &&
      user?.org?.ID != 0 &&
      showIncentiveBanner
    ) {
      amplitude.getInstance().logEvent("invite incentive banner shown", {});
    }
  }, [user]);

  const fetchRecordings = async () => {
    try {
      if (userEmail) {
        setRecordings(recordings);

        const url =
          props.view === "shared_with_me"
            ? `https://backend.scribbl.co/enhanced-recording?view=shared_with_me&email=${userEmail}`
            : `https://backend.scribbl.co/enhanced-recording?view=my_meetings&email=${userEmail}`;

        const enhancedRecResoponse = await fetch(url, {
          method: "GET",
          credentials: "include",
        });

        if (enhancedRecResoponse.status === 401) {
          setLogout(true);
        }

        const enhancedRecordings = await enhancedRecResoponse.json();
        if (!enhancedRecordings) {
          setRecordings([]);
          return;
        }
        enhancedRecordings.forEach(
          (recording) => (recording.enhancedRecording.role = recording.Role)
        );
        setRecordings(
          enhancedRecordings
            .map((recording) => recording.enhancedRecording)
            .sort(
              (a, b) =>
                moment(b.CreatedAt, "YYYY-MM-DDTHH:mm:ss") -
                moment(a.CreatedAt, "YYYY-MM-DDTHH:mm:ss")
            )
            .map((recording) => ({
              ...recording,
            }))
        );
        // setLoading(false);

        var eventProperties = {
          tab_name:
            props.view === "shared_with_me" ? "Shared With Me" : "My Meetings",
        };
        amplitude.getInstance().logEvent("er list tab viewed", eventProperties);
      }
    } catch (exception) {
      console.log(exception);
      setRecordings(recordings);
      setLoading(false);
    }
  };

  const openDeleteModalClicked = (recording) => {
    setRecordingToDelete(recording); // both of these will cause a re-render. It doesn't look bad, but we should store this in a normal variable or ref.
    setOpenDeleteModal(true);
  };

  const handleSearch = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    setSearchTerm(searchTerm);
    setCurrentPage(0);

    if (!recordings) {
      return;
    }

    const filteredRecordings = recordings.filter((recording) => {
      const titleMatch = recording.meetingTitle
        .toLowerCase()
        .includes(searchTerm);
      const attendeeMatch = recording.attendees.some((attendee) =>
        attendee.name.toLowerCase().includes(searchTerm)
      );
      return titleMatch || attendeeMatch;
    });

    setRecordingsToDisplay(filteredRecordings);
  };

  const deleteRecording = async () => {
    if (recordingToDelete) {
      setLoading(true);
      const deleteRecordingResponse = await fetch(
        `https://backend.scribbl.co/enhanced-recording/${recordingToDelete.uuid}/delete`,
        {
          method: "POST",
          body: JSON.stringify({
            email: userEmail,
          }),
          credentials: "include", // This line ensures cookies are sent with the request
        }
      );
      await fetchRecordings();
      setLoading(false);
    }
  };

  // Recordings to show
  let startAt = 1;
  let endAt = 5;
  if (recordingsToDisplay) {
    startAt = currentPage * pageLength + 1;
    endAt =
      currentPage * pageLength + pageLength <= recordingsToDisplay.length
        ? currentPage * pageLength + pageLength
        : recordingsToDisplay.length;
  }

  return (
    <Navbar
      setUserEmail={setUserEmail}
      setUser={setUser}
      loading={loading}
      setLoading={setLoading}
      setLogout={setLogout}
      logout={logout}
    >
      {/* Main area */}
      <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
        {user &&
          !user.subscription &&
          user?.org?.ID != 0 &&
          showIncentiveBanner && (
            <div className="flex items-center gap-x-6 bg-brand-green-lighter1 px-6 py-2.5 sm:px-3.5 sm:before:flex-1">
              <p className="text-sm leading-6 text-white">
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    setOpenBannerInviteModal(true);
                  }}
                >
                  <GiftIcon
                    className="h-4 w-4 inline-block mr-1.5 mt-[-3px]"
                    aria-hidden="true"
                  />
                  Invite two team members to earn an additional 15 credits&nbsp;
                  <span aria-hidden="true">&rarr;</span>
                </a>
              </p>
              <div className="flex flex-1 justify-end">
                <button
                  type="button"
                  className="-m-3 p-3 focus-visible:outline-offset-[-4px]"
                  onClick={() => setShowIncentiveBanner(false)}
                >
                  <span className="sr-only">Dismiss</span>
                  <XIcon className="h-5 w-5 text-white" aria-hidden="true" />
                </button>
              </div>
              <InviteModal
                open={openBannerInviteModal}
                setOpen={setOpenBannerInviteModal}
                org={user.org}
                email={userEmail}
                giftCredits={true}
              />
            </div>
          )}

        {/* Breadcrumb */}
        <div className="py-6">
          {props.view === "my_meetings" && !user?.subscription && (
            <div className="float-right">
              <Tippy
                theme="light"
                interactive={true}
                content={
                  <div>
                    {user?.tokensRemaining !== 0 ? (
                      <div className="p-2 text-center">
                        Upgrade to get more meetings and unlock all of your
                        locked meetings. Otherwise, we’ll replenish your credits
                        on the 1st of the month 😉
                      </div>
                    ) : (
                      <div className="p-2 text-center">
                        Looks like you're out of credits. Any new meetings will
                        be locked going forward. Upgrade to get more meetings
                        and unlock all of your locked meetings. Otherwise, we’ll
                        replenish your credits on the 1st of the month 😉
                      </div>
                    )}
                  </div>
                }
              >
                <span
                  className={`text-sm font-medium align-top mr-3 ${
                    user?.tokensRemaining < 5
                      ? "text-brand-red"
                      : "text-brand-green"
                  }`}
                >
                  {user?.tokensRemaining} Meeting
                  {user?.tokensRemaining !== 1 ? "s" : ""} Remaining
                </span>
              </Tippy>
              <button
                onClick={() => window.open("/pricing", "_blank").focus()}
                className="bg-brand-green-lighter1 relative inline-flex items-center px-4 py-2 mr-2.5 text-sm font-medium rounded-md text-white hover:bg-brand-green-lighter3 -mt-2"
              >
                <LightningBoltIcon
                  className="h-4 w-4 mr-1"
                  aria-hidden="true"
                />
                Upgrade
              </button>
            </div>
          )}
          <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
            <h1 className="text-2xl font-semibold text-gray-900">
              {props.view === "shared_with_me"
                ? "Meetings Shared With Me"
                : "My Meetings"}
            </h1>
          </div>

          <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
            {/* Replace with your content */}
            <div className="py-4">
              <div className="h-75 mb-16 bg-white flex flex-col rounded-md border border-gray-200">
                {/* Search bar */}
                <div className="w-full px-2 lg:px-6">
                  <label htmlFor="search" className="sr-only">
                    Search meetings
                  </label>
                  <div className="relative focus-within:text-gray-400 m-3">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <SearchIcon
                        className="h-5 w-5 text-brand-green-lighter3"
                        aria-hidden="true"
                      />
                    </div>
                    <input
                      id="search"
                      name="search"
                      className="block w-full pl-10 pr-3 py-2 border-none focus:outline-none focus:bg-white focus:ring-0 focus:placeholder-gray-400 focus:text-gray-900 sm:text-sm"
                      placeholder="Search meetings"
                      type="search"
                      onChange={handleSearch}
                    />
                  </div>
                </div>

                {/* Meetings list and pagination */}

                <div
                  className="h-full bg-white flex flex-col border-t border-gray-200 rounded-b-md"
                  style={{ minHeight: 500 }}
                >
                  {recordingsToDisplay && recordingsToDisplay.length > 0 && (
                    <div>
                      <ul className="divide-y divide-gray-200">
                        {/* Meetings list */}
                        {recordingsToDisplay
                          .slice(startAt - 1, endAt)
                          .map((recording) => {
                            return (
                              <ListViewRecording
                                key={recording.ID}
                                recording={recording}
                                searchTerm={searchTerm}
                                openDeleteModal={openDeleteModalClicked}
                                listviewContext={
                                  props.view === "shared_with_me"
                                    ? "shared_with_me"
                                    : "my_meetings"
                                }
                              ></ListViewRecording>
                            );
                          })}
                      </ul>

                      {/* Pagination */}
                      <nav
                        className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6"
                        aria-label="Pagination"
                      >
                        <div className="hidden sm:block">
                          <p className="text-sm text-gray-700">
                            Showing{" "}
                            <span className="font-medium">{startAt}</span> to{" "}
                            <span className="font-medium">{endAt}</span> of{" "}
                            <span className="font-medium">
                              {recordingsToDisplay.length}
                            </span>{" "}
                            results
                          </p>
                        </div>
                        <div className="flex-1 flex justify-between sm:justify-end">
                          <button
                            onClick={() =>
                              currentPage > 0 && setCurrentPage(currentPage - 1)
                            }
                            className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                          >
                            Previous
                          </button>
                          <button
                            onClick={() =>
                              (currentPage + 1) * pageLength <=
                                recordingsToDisplay.length &&
                              setCurrentPage(currentPage + 1)
                            }
                            className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                          >
                            Next
                          </button>
                        </div>
                      </nav>
                    </div>
                  )}
                  {/* Empty state */}
                  {recordingsToDisplay &&
                    recordingsToDisplay.length === 0 &&
                    !searchTerm &&
                    !loading && (
                      <div className="text-center py-16">
                        <svg
                          className="mx-auto h-12 w-12 text-gray-400"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            vectorEffect="non-scaling-stroke"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
                          />
                        </svg>
                        <h3 className="mt-2 text-sm font-medium text-gray-900">
                          No Meetings
                        </h3>
                        <p className="mt-1 text-sm text-gray-500">
                          Install the Scribbl Chrome extension to start
                          recording meetings.
                        </p>
                        <div className="mt-6">
                          <button
                            type="button"
                            onClick={() =>
                              window.open(
                                "https://chrome.google.com/webstore/detail/google-meet-transcripts-b/kmjmlilenakedodldceipdnmmnfkahni?hl=en-US",
                                "_blank"
                              )
                            }
                            className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-brand-green hover:bg-brand-green-darker2 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-green"
                          >
                            Go to Chrome Store
                          </button>
                          <p className="mt-24 text-xs text-gray-500">
                            For more assistance, check out our documentation for
                            recording meetings.
                          </p>
                        </div>
                      </div>
                    )}
                  {/* Delete Recording Modal */}
                  <Transition.Root show={openDeleteModal} as={Fragment}>
                    <Dialog
                      as="div"
                      className="fixed z-10 inset-0 overflow-y-auto"
                      onClose={setOpenDeleteModal}
                    >
                      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                        <Transition.Child
                          as={Fragment}
                          enter="ease-out duration-300"
                          enterFrom="opacity-0"
                          enterTo="opacity-100"
                          leave="ease-in duration-200"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                        >
                          <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                        </Transition.Child>

                        {/* This element is to trick the browser into centering the modal contents. */}
                        <span
                          className="hidden sm:inline-block sm:align-middle sm:h-screen"
                          aria-hidden="true"
                        >
                          &#8203;
                        </span>
                        <Transition.Child
                          as={Fragment}
                          enter="ease-out duration-300"
                          enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                          enterTo="opacity-100 translate-y-0 sm:scale-100"
                          leave="ease-in duration-200"
                          leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                          leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                          <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                            <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                              <button
                                type="button"
                                className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-0"
                                onClick={() => setOpenDeleteModal(false)}
                              >
                                <span className="sr-only">Close</span>
                                <XIcon className="h-6 w-6" aria-hidden="true" />
                              </button>
                            </div>
                            <div className="sm:flex sm:items-start">
                              <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                <ExclamationIcon
                                  className="h-6 w-6 text-red-600"
                                  aria-hidden="true"
                                />
                              </div>
                              <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                <Dialog.Title
                                  as="h3"
                                  className="text-lg leading-6 font-medium text-gray-900"
                                >
                                  Delete Recording
                                </Dialog.Title>
                                <div className="mt-2">
                                  <p className="text-sm text-gray-500">
                                    Are you sure you want to delete{" "}
                                    {recordingToDelete &&
                                      recordingToDelete.meetingTitle}
                                    ?
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                              <button
                                type="button"
                                className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                                onClick={() => {
                                  deleteRecording();
                                  setOpenDeleteModal(false);
                                }}
                              >
                                Delete Recording
                              </button>
                              <button
                                type="button"
                                className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                                onClick={() => setOpenDeleteModal(false)}
                              >
                                Cancel
                              </button>
                            </div>
                          </div>
                        </Transition.Child>
                      </div>
                    </Dialog>
                  </Transition.Root>
                  {/* Search empty state */}
                  {recordingsToDisplay &&
                    recordingsToDisplay.length === 0 &&
                    searchTerm && (
                      <div className="text-center py-16">
                        <SearchIcon
                          className="mx-auto h-12 w-12 text-gray-400"
                          aria-hidden="true"
                        />
                        <h3 className="mt-2 text-sm font-medium text-gray-900">
                          No Meetings Found
                        </h3>
                        <p className="mt-1 text-sm text-gray-500">
                          No meetings found matching <b>{searchTerm}</b>
                        </p>
                      </div>
                    )}
                </div>
              </div>
            </div>
            {/* /End replace */}
          </div>
        </div>
      </main>
    </Navbar>
  );
};

export default Home;
